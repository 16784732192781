import Icon from "@mui/icons-material/Person";
import List from "./list";

export default {
  list: List,
  icon: Icon,
  label: "Speakers",
  options: { label: "Speakers" },
  name: "exhibitorSpeakers",
};
