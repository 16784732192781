/* eslint-disable no-console */
import React, { createContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { getEventId } from "../utils";
import { callApiWithoutAuth } from "./miscApis";
export const EventContext = createContext();

export default ({ children }) => {
  const [events, setEvents] = useState([]);
  const [openDialog, setDialog] = useState(false);
  const [meta, setMeta] = useState({});
  const currentEventId = getEventId();
  const currentEvent = events.find((event) => event.id === currentEventId) || {};

  useEffect(() => {
    callApiWithoutAuth("getMeta", {}, "GET")
      .then(({ data }) => {
        const row = data.rows?.[0] || {};
        setMeta(row);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <EventContext.Provider
      value={{
        events,
        setEvents,
        currentEvent,
        currentEventId,
        setDialog,
        openDialog,
        meta,
      }}
    >
      {meta.url && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{meta.name}</title>
          <link rel="canonical" href={meta.url} />
          <link rel="icon" href={meta.favicon || "/favicon.ico"} />
        </Helmet>
      )}

      {children}
    </EventContext.Provider>
  );
};
