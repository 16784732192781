// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB1XDCLzFimH6_7MCN29iQLW48e1Z00G3Q",
  authDomain: "re-exhibitors.firebaseapp.com",
  projectId: "re-exhibitors",
  storageBucket: "re-exhibitors.appspot.com",
  messagingSenderId: "394121573781",
  appId: "1:394121573781:web:4611bdb75495947f40729c",
  measurementId: "G-DBT671XBWX",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
export const authObj = getAuth(app);

export default storage;
