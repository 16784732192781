/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { Datagrid, List, TextInput, FunctionField, downloadCSV, TextField } from "react-admin";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import copy from "copy-to-clipboard";
import jsonExport from "jsonexport/dist";

const exporter = (registrations) => {
  const speakersExport = registrations.map((registration) => {
    const { firstName, lastName, title, phone, email, company, country, privacyPolicy } =
      registration;
    return {
      name: `${firstName} ${lastName}`,
      title,
      company,
      country,
      phone,
      email,
      dataConsent: privacyPolicy ? "YES" : "NO",
    };
  });
  jsonExport(
    speakersExport,
    {
      headers: ["name", "title", "company", "country", "phone", "email", "dataConsent"],
    },
    (err, csv) => {
      downloadCSV(csv, "delegates");
    }
  );
};

const ImageFieldColumn = ({ data: record }) =>
  record.image ? (
    <img src={record.image} width="60px" alt={record.name} />
  ) : (
    <img
      src={`https://ui-avatars.com/api/?background=1a237e&color=fff&name=${record.name}`}
      width="60px"
      alt={record.name}
    />
  );

const RegistrationFilters = [<TextInput label="Search" source="search" alwaysOn key={0} />];
export default (props) => (
  <List
    {...props}
    sort={{ field: "createdAt", order: "DESC" }}
    perPage={15}
    filters={RegistrationFilters}
    hasCreate={false}
    hasedit="false"
    exporter={exporter}
  >
    <Datagrid rowClick="" bulkActionButtons={false}>
      <FunctionField
        label="Image"
        source="image"
        render={(record) => <ImageFieldColumn data={record} />}
        key="image"
      />
      <TextField source="name" />
      <TextField source="title" />

      <FunctionField
        label="Company"
        render={(record) => `${record.company}, ${record.country}`}
        key="Info"
      />
      <FunctionField
        label="Phone"
        source="phone"
        render={(record) => (
          <Tooltip title="Click to copy">
            <Link
              component="button"
              onClick={() => {
                copy(`+${record.phone}`);
              }}
              underline="none"
            >
              +{record.phone}
            </Link>
          </Tooltip>
        )}
        key="phone"
      />
      <FunctionField
        label="Email"
        source="email"
        render={(record) => (
          <Tooltip title="Click to copy">
            <Link
              component="button"
              onClick={() => {
                copy(record.email);
              }}
              underline="none"
            >
              {record.email}
            </Link>
          </Tooltip>
        )}
        key="email"
      />
    </Datagrid>
  </List>
);
