/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import React, { useState } from "react";
import { QrReader } from "react-qr-reader";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import { useNotify } from "react-admin";
import useMediaQuery from "@mui/material/useMediaQuery";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import SpeakerIcon from "@mui/icons-material/Person";
import DelegateIcon from "@mui/icons-material/AccountBox";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";

import boop from "../../assets/beep-07a.mp3";
import { callApi } from "../../dataprovider/miscApis";
import FullPageLoader from "../../components/fullPageLoader";

const QrCheck = () => {
  const navigate = useNavigate();
  const audio = new Audio(boop);
  const matches = useMediaQuery("(max-width:600px)");
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const onQrCheck = async (result, error) => {
    if (result) {
      audio.play();

      const text = result?.text ? new URL(result?.text) : {};
      if (text.pathname) {
        setLoading(true);
        await callApi(`exhibitorRegistrations/unlock/${text.pathname.substring(1)}`)
          .then(() => {
            notify("Scanned Successfully");
          })
          .catch((err) => {
            notify(`INVALID QR ${err.message}`, { type: "warning" });
          })
          .finally(() => {
            setLoading(false);
          });
        return;
      }
      notify("INVALID QR", { type: "warning" });
      return;
    }

    if (error) {
      console.info(error);
    }
  };

  const onClick = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: {
            exact: "environment",
          },
        },
      })
      .then((stream) => {
        const el = document.getElementById("TEST123");
        el.srcObject = stream;
        // el.style.transform = "rotateY(180deg)";
      })
      .catch(console.error);
  };

  const [value, setValue] = React.useState(0);
  return (
    <>
      {loading && <FullPageLoader />}
      <div
        style={{
          height: "100vh",
          width: "100vw",
        }}
      >
        <QrReader
          style={{ width: "100%" }}
          videoContainerStyle={{ padding: 0, position: "static" }}
          videoStyle={{ top: "0px", transform: "rotateY(180deg)" }}
          videoId="TEST123"
          constraints={{ facingMode: "enviroment" }}
          onResult={onQrCheck}
          scanDelay={2000}
        />
      </div>
      <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          {matches && (
            <BottomNavigationAction
              label="Switch Camera"
              icon={<CameraswitchIcon />}
              onClick={onClick}
            />
          )}
          <BottomNavigationAction
            label="View Delegates"
            icon={<DelegateIcon />}
            onClick={() => {
              navigate("/exhibitorRegistrations");
            }}
          />
          <BottomNavigationAction
            label="View Speakers"
            icon={<SpeakerIcon />}
            onClick={() => {
              navigate("/exhibitorSpeakers");
            }}
          />
        </BottomNavigation>
      </Paper>
    </>
  );
};

export default QrCheck;
