/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { AppBar, Logout, UserMenu, useSidebarState } from "react-admin";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import Typography from "@mui/material/Typography";

import { EventContext } from "../dataprovider/eventProvider";
import Logo from "../assets/logo.png";

export default (props) => {
  const { events, currentEventId, currentEvent, meta } = useContext(EventContext);
  const [current, setCurrent] = useState("Dashboard");

  const activeEvents = [];
  events.map((e) => {
    if (dayjs(e.toDate) > dayjs()) {
      activeEvents.push(e);
    }
    return e;
  });

  const [open, setOpen] = useSidebarState();

  useEffect(() => {
    if (currentEvent) {
      setCurrent(currentEvent.name);
    }
  }, [currentEvent]);

  const CustomUserMenu = () => (
    <UserMenu>
      <Logout />
    </UserMenu>
  );

  useEffect(() => {
    setOpen(false);
  }, [current]);

  return (
    <AppBar
      sx={{
        "& .RaAppBar-menuButton svg": {
          color: (theme) => theme.palette.primary.main,
        },
      }}
      elevation={3}
      open={open}
      {...props}
      userMenu={<CustomUserMenu />}
    >
      <Box
        style={{
          margin: "5px 0px",
          padding: "0 5px 0 6px",
          display: "flex",
          visibility: meta.url ? "visible" : "hidden",
        }}
      >
        <img src={meta.logo || Logo} alt="logo" style={{ width: "100px" }} />
      </Box>
      {/* <Box
        style={{
          margin: "5px 0px",
          padding: "0 5px 0 6px",
          display: "flex",
          borderLeft: "1px solid #afafaf",
          // visibility: meta.url ? "visible" : "hidden",
        }}
      >
        <Button variant="link" href="#/qrcheck" startIcon={<QrCodeScannerIcon />}>
          Scan
        </Button>
      </Box> */}

      <Box flex="1" style={{ marginLeft: 15 }}>
        <Typography variant="h5" color="primary" noWrap>
          {current}
        </Typography>
      </Box>
    </AppBar>
  );
};
