/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React from "react";
import { Admin, Resource, defaultTheme, CustomRoutes } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import { red } from "@mui/material/colors";
import { Route } from "react-router-dom";

import withClearCache from "./ClearCache";

import dataProvider from "./dataprovider";
import authProvider from "./authProvider";
import layout from "./layout";
import EventProvider from "./dataprovider/eventProvider";
import { getEventId } from "./utils";
import MyLoginPage from "./layout/login";
import ForgotPassword from "./layout/forgotPassword";
import delegate from "./containers/delegate";
import speaker from "./containers/speaker";
import QRcheck from "./containers/qrcheck";

const messages = {
  en: englishMessages,
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "en", {
  allowMissing: true,
});

const MainApp = () => {
  const eventId = getEventId();
  return (
    <EventProvider>
      <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        layout={layout}
        theme={appTheme}
        loginPage={MyLoginPage}
      >
        <Resource {...delegate} />
        <Resource {...speaker} />
        <CustomRoutes noLayout>
          <Route path="/qrcheck" element={<QRcheck />} />
        </CustomRoutes>
        <CustomRoutes noLayout>
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </CustomRoutes>
      </Admin>
    </EventProvider>
  );
};

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}
const appTheme = {
  ...defaultTheme,
  palette: {
    mode: "light",
    primary: {
      main: "#0A1F32",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#ffffff",
      contrastText: "#0A1F32",
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ["Quantico", "sans-serif"].join(","),
  },
  components: {
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          // paddingLeft: "12px",
          "&.RaMenuItemLink-active": {
            background: "#6bd0dc",
            color: "#ffffff",
            "& .RaMenuItemLink-icon": {
              color: "#ffffff",
            },
          },
          "& .RaMenuItemLink-icon": {
            color: "#0A1F32",
          },
        },
      },
    },
  },
  overrides: {
    MuiButton: {
      root: {
        color: "light", // Some CSS
      },
    },
  },
};

export default App;
