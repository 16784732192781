import Icon from "@mui/icons-material/AccountBox";
import List from "./list";

export default {
  list: List,
  icon: Icon,
  label: "Delegates",
  options: { label: "Delegates" },
  name: "exhibitorRegistrations",
};
