/* eslint-disable no-param-reassign */
import { onAuthStateChanged, getIdToken } from "firebase/auth";
import { fetchUtils } from "react-admin";
import { authObj } from "../firebaseConfig";
import { getEventId } from "../utils";

export default async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({});
  }
  const eventId = getEventId();
  let token = "";
  const currentUser = await new Promise((resolve, reject) => {
    onAuthStateChanged(authObj, async (user) => {
      if (user) {
        const to = await getIdToken(user, true).then((t) => t);
        return resolve(to);
      }
      return reject();
    });
  });

  if (currentUser) {
    token = currentUser;
  }
  options.headers.set("Accept", "application/json");
  options.headers.set("Authorization", token);
  options.headers.set("EventID", eventId);
  return fetchUtils.fetchJson(url, options);
};
