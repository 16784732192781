import dayjs from "dayjs";

export const getEventId = () => {
  const { href } = window.location;
  const u = new URL(href);
  return u.pathname.substring(1).replace(/\/$/, "");
};
export const goToEvent = (id) => {
  const { href } = window.location;
  const url = new URL(href);
  window.location = `${url.origin}/${id}/#/`;
};
export const goToDashboard = () => {
  const { href } = window.location;
  const url = new URL(href);
  window.location = `${url.origin}/#/`;
};
export const goToLogin = () => {
  const { href } = window.location;
  const url = new URL(href);
  window.location = `${url.origin}/#/login`;
};

const getTimezoneOffset = (value) => value.getTimezoneOffset() * 60000;
export const parseDateTime = (value) => {
  const dateTime = new Date(value);
  const utcFromLocal = new Date(dateTime.getTime() - getTimezoneOffset(dateTime));
  return utcFromLocal;
};
export const formatDateTime = (value) => {
  const dateTime = new Date(value);
  const utcFromLocal = new Date(dateTime.getTime() + getTimezoneOffset(dateTime));
  return dayjs(utcFromLocal).format("YYYY-MM-DDTHH:mm");
};
