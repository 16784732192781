// export const apiUrl = "http://localhost:3012";
export const apiUrl = "https://api.rapid-events.com";

export const permissionsList = [
  { id: "eventDashboard", name: "Event Dashboard" },
  { id: "registrations.view", name: "View Registrations" },
  { id: "registrations.create", name: "Create Registrations" },
  { id: "registrations.edit", name: "Edit Registrations" },
  { id: "registrations.delete", name: "Delete Registrations" },
  { id: "speaker.view", name: "View Speakers", checked: true },
  { id: "speaker.create", name: "Create Speakers", checked: true },
  { id: "speaker.edit", name: "Edit Speaker", checked: true },
  { id: "speaker.delete", name: "Delete Speakers", checked: true },
  { id: "sponsor.view", name: "View Sponsors", checked: true },
  { id: "sponsor.create", name: "Create Sponsors", checked: true },
  { id: "sponsor.edit", name: "Edit Sponsors", checked: true },
  { id: "sponsor.delete", name: "Delete Sponsors", checked: true },
  { id: "agenda.view", name: "View Agenda", checked: true },
  { id: "agenda.create", name: "Create Agenda", checked: true },
  { id: "agenda.edit", name: "Edit Agenda", checked: true },
  { id: "agenda.delete", name: "Delete Agenda", checked: true },
  { id: "events.view", name: "View Events" },
  { id: "events.create", name: "Create Events" },
  { id: "events.edit", name: "Edit Events" },
  { id: "events.delete", name: "Delete Events" },
  { id: "admins.view", name: "View Admin" },
  { id: "admins.create", name: "Create Admin" },
  { id: "admins.edit", name: "Edit Admin" },
  { id: "admins.delete", name: "Delete Admin" },
  { id: "exhibitors.view", name: "View Exhibitor" },
  { id: "exhibitors.create", name: "Create Exhibitor" },
  { id: "exhibitors.edit", name: "Edit Exhibitor" },
  { id: "exhibitors.delete", name: "Delete Exhibitor" },
  { id: "scanqr", name: "Scan QR" },
];
