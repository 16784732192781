import React, { useContext, useState } from "react";
import { useLogin, useNotify } from "react-admin";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Logo from "../assets/logo.png";
// import background from "../assets/Portal-Image_1200X1200_2.png";
import { EventContext } from "../dataprovider/eventProvider";

export default function SignInSide() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { meta } = useContext(EventContext);
  const [loading, setLoading] = useState(false);
  const login = useLogin();
  const notify = useNotify();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    login({ username, password })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        notify("Invalid email or password");
        setLoading(false);
      });
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={8}
        sx={{
          backgroundImage: meta.mainImage ? `url(${meta.mainImage})` : "",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: 0,
            textAlign: "center",
            padding: "35px",
          }}
        >
          {/* <Typography color="primary" variant="body1">
            Powered By
          </Typography>
          <img src={Logo} alt="logo" style={{ maxWidth: "90px" }} /> */}
        </div>
      </Grid>
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6}>
        <Box
          sx={{
            my: 8,
            mx: 4,
            height: "65vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ visibility: meta.url ? "visible" : "hidden" }}>
            <img
              src={meta.logo || Logo}
              alt="logo"
              style={{ maxWidth: "250px", marginBottom: "15px" }}
            />
          </Box>

          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              variant="standard"
              autoFocus
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              variant="standard"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />
            <Box style={{ textAlign: "right" }}>
              <Link href="#/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Box>
            <Box style={{ textAlign: "center" }}>
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                sx={{
                  mt: 5,
                  mb: 2,
                  mx: "auto",
                  width: "160px",
                  height: "40px",
                  borderRadius: 3,
                }}
              >
                Sign In {loading && <CircularProgress color="inherit" size={20} sx={{ ml: 1 }} />}
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
