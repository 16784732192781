/* eslint-disable no-unused-vars */
import axios from "axios";
import { onAuthStateChanged, getIdToken } from "firebase/auth";
import { authObj } from "../firebaseConfig";
import { apiUrl } from "../constants";
import { getEventId } from "../utils";

export const callApi = async (location, body, requestType) => {
  let token = "";
  const currentUser = await new Promise((resolve, reject) => {
    onAuthStateChanged(authObj, async (user) => {
      if (user) {
        const to = await getIdToken(user).then((t) => t);
        return resolve(to);
      }
      return reject();
    });
  });

  if (currentUser) {
    token = currentUser;
  }
  return axios({
    method: requestType || "POST",
    url: `${apiUrl}/${location}`,
    data: body,
    headers: {
      "Content-Type": "application/json",
      authorization: token,
      eventid: getEventId(),
    },
  }).then((response) => response);
};

export const callApiWithoutAuth = async (location, body, requestType) =>
  axios({
    method: requestType || "POST",
    url: `${apiUrl}/${location}`,
    data: body,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response);
